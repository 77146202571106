<script setup lang="ts">
import { CmsBlock } from '@shopware-pwa/types';
import { getCmsLayoutConfiguration } from '@shopware-pwa/helpers-next';
import { resolveCmsComponent } from '@shopware-pwa/composables-next';
const props = defineProps<{
  content: CmsBlock;
}>();

const DynamicRender = () => {
  //const isClient = typeof window !== 'undefined';
  //const isMobile = isClient ? window.innerWidth <= 768 : false;

  const { resolvedComponent, componentName, isResolved } = resolveCmsComponent(
    props.content
  );

  if (resolvedComponent) {
    if (!isResolved)
      return h('div', {}, 'Problem resolving component: ' + componentName);

    let { cssClasses, layoutStyles } = getCmsLayoutConfiguration(props.content);

    // if (isClient && isMobile && layoutStyles.marginBottom) {
    //   const marginBottomValue = parseFloat(layoutStyles.marginBottom);
    //   layoutStyles.marginBottom = (marginBottomValue / 2) + 'px';
    // }

    // watch(
    //   () => layoutStyles.marginBottom,
    //   (newMarginBottom) => {
    //     containerStyles.marginBottom = newMarginBottom;
    //   }
    // );

    const containerStyles = {
      backgroundColor: layoutStyles.backgroundColor,
      backgroundImage: layoutStyles.backgroundImage,
    };

    layoutStyles.backgroundColor = null;
    layoutStyles.backgroundImage = null;

    cssClasses = cssClasses + ' mb-' + layoutStyles.marginBottom;
    layoutStyles.marginBottom = null;
    //containerStyles.marginBottom = null;

    return h(
      'div',
      { style: containerStyles },
      h(resolvedComponent, {
        content: props.content,
        style: layoutStyles,
        class: cssClasses,
      })
    );
  }

  return h('div', {}, 'Loading...block_' + componentName);
};
</script>

<template>
  <DynamicRender />
</template>

<style lang="scss">
$count: 5;

@while $count <= 150 {
  .mb-#{$count}px {
    margin-bottom: #{$count}px;
    @media only screen and (max-width: 1023px) {
      margin-bottom: #{calc($count / 2)}px;
    }
  }

  $count: $count + 5;
}
</style>
